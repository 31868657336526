import Container_BubbleLeftAreaComponent from '../components/Container/viewer/skinComps/BubbleLeftArea/BubbleLeftArea.skin';


const Container_BubbleLeftArea = {
  component: Container_BubbleLeftAreaComponent
};


export const components = {
  ['Container_BubbleLeftArea']: Container_BubbleLeftArea
};

